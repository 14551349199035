<template>

<div class="dashboard" v-if="!is.loading">

	<app-icon icon="empty" class="dashboard-empty" v-if="isEmpty" />

	<div class="dashboard-title" v-if="isEmpty">Hi, {{ firstname }}!</div>
	<div class="dashboard-text" v-if="isEmpty">Sorry, but it seems you have no guides available to manage currently.</div>

	<div class="dashboard-title" v-if="!isEmpty">Hi, {{ firstname }}!</div>
	<div class="dashboard-text" v-if="!isEmpty">Where would you like to go today?</div>

	<div class="dashboard-guides-item is-new" v-tooltip="'Create new guide'" key="new" v-if="create" v-on:click="onCreateClick"><app-icon icon="add" /></div>

	<div class="dashboard-guides" v-if="!isEmpty">

		<input type="text" placeholder="Quick search..." v-model="search" class="dashboard-search" />

		<div class="dashboard-guides-item" v-on:click="onContextClick(context.key)" v-for="context in filteredContexts" :key="context.key" :style="{backgroundColor: '#' + context.color.back, color: '#' + context.color.fore}" v-tooltip="'Go to ' + context.name">{{ context.name }}</div>

	</div>

	<div class="dashboard-create" :class="{'is-active': is.creating}">

		<h1>Create guide</h1>

		<app-input-text v-model="model.name" :maxlength="32" :validation="$v.model.name" label="Name" placeholder="Enter name" />
		<app-input-text v-model="model.initials" :maxlength="2" :validation="$v.model.initials" label="Initials" placeholder="Enter initials" />
		<app-input-colour v-model="model.color.fore" :validation="$v.model.color.fore" label="Foreground" placeholder="Enter color" />
		<app-input-colour v-model="model.color.back" :validation="$v.model.color.back" label="Background" placeholder="Enter color" />
		<app-input-select v-model="model.clone" :asString="true" :validation="$v.model.clone" label="Clone" :options="cloneOptions" placeholder="Select optional clone" />

		<div class="dashboard-create-buttons">
			<app-button theme="blue" text="Confirm" :loading="is.confirming" :disabled="$v.$invalid" v-on:click="onConfirmClick" />
			<app-button theme="plain" text="Cancel" v-on:click="is.creating = false" />
		</div>

	</div>

</div>

</template>

<script>

import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate';

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			is: {
				loading: true,
				creating: false,
				confirming: false
			},
			contexts: [],
			create: false,
			name: '',
			search: '',
			model: {
				initials: '',
				name: '',
				color: {
					fore: 'ffffff',
					back: '000000'
				},
				clone: 0
			}
		}

	},

	validations: {
		model: {
			initials: {
				required,
				maxLength: maxLength(2)
			},
			name: {
				required,
				maxLength: maxLength(32)
			},
			color: {
				fore: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(6)
				},
				back: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(6)
				}
			}
		}
	},

	created: function() {

		this.$store.commit('context', false)
		this.$store.commit('ready', false)

		this.$api.get('contexts').then(function(json) {

			this.contexts = json.contexts
			this.create = json.create
			this.name = json.user.name

			if (this.contexts.length === 1 && !this.create) {

				this.onContextClick(this.contexts[0].key)

			} else {

				this.is.loading = false

				this.$store.commit('ready', true)

			}


		}.bind(this))

	},

	computed: {

		filteredContexts: function() {

			if (!this.search) return this.contexts

			var contexts = []

			this.$_.each(this.contexts, function(item) {
			
				if (item.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) contexts.push(item)
			
			}.bind(this))

			return contexts

		},

		cloneOptions: function() {

			var options = {}

			this.$_.each(this.contexts, function(item) {

				options[item.key] = item.name

			})

			return options

		},

		firstname: function() {

			return this.name.split(' ')[0]

		},

		isEmpty: function() {

			return !this.contexts.length && !this.create

		}

	},

	methods: {

		onConfirmClick: function() {

			this.is.confirming = true

			this.$api.post('contexts', this.model).then(function(json) {
				
				this.onContextClick(json.key)

			}.bind(this), function() {

				this.is.confirming = false

			}.bind(this))

		},

		onCreateClick: function() {

			this.is.creating = true

		},

		onContextClick: async function(key) {

			this.is.loading = true

			this.$store.commit('context', key)
			this.$store.commit('ready', false)

			await this.$store.dispatch('load')

			this.$router.push({
				name: 'Categories'
			})

		}

	}
	
}

</script>

<style scoped>

.dashboard {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow-y: auto;
	padding: 20px;
}

.dashboard-title {
	color: #fff;
	font-size: 32px;
	font-weight: 400;
	text-align: center;
	letter-spacing: 1px;
	margin-bottom: 5px;
}

.dashboard-search {
	background-color: #fff;
	border-radius: 10px;
	max-width: 640px;
	margin: 0px auto 10px auto;
	display: block;
	height: 40px;
	font-size: 20px;
	padding: 0px 10px;
	width: 100%;
}

.dashboard-text {
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
}

.dashboard-guides {
	margin-top: 30px;
}

.dashboard-guides-item {
	margin-bottom: 10px;
	line-height: 40px;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 1px;
	width: 100%;
	height: 40px;
	border-radius: 10px;
	transition: all 100ms linear;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.25);
	padding: 0px 10px;
	max-width: 640px;
	margin: 0px auto 10px auto;
}

.dashboard-guides-item:hover {
	transform: scale(1.02);
}

.dashboard-guides-item.is-new {
	border: 2px dashed #fff;
	color: #fff;
	font-size: 24px;
	position: absolute;
	right: 20px;
	top: 20px;
	text-align: center;
	height: 52px;
	line-height: 50px;
	border-radius: 50%;
	width: 52px;
}

.dashboard-empty {
	color: #fff;
	font-size: 128px;
	margin: 0px auto;
	margin-bottom: 40px;
}

.dashboard-create {
	position: absolute;
	left: 50%;
	top: -50%;
	width: 640px;
	transform: translate(-50%, -50%);
	background-color: #fff;
	z-index: 100;
	padding: 20px;
	transition: top 300ms ease-in-out;
	border-radius: 10px;
}

.dashboard-create.is-active {
	top: 50%;
}

.dashboard-create h1 {
	font-size: 21px;
	margin-bottom: 20px;
	color: #0b234e;
}

.dashboard-create-buttons {
	display: flex;
	justify-content: center;
}

.dashboard-create-buttons >>> .button {
	margin: 0px 5px;
}

</style>
